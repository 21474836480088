import { Button, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const MintButton = (props) => {
  function IncDec() {
    const IncNum = () => {
      if (props.count < props.maxMintAmount) {
        props.setCount(props.count + 1);
      } else {
        props.setCount(props.maxMintAmount);
      }
    };
    const DecNum = () => {
      if (props.count > 1) props.setCount(props.count - 1);
      else {
        props.setCount(1);
      }
    };
    return (
      <>
        <div className="incr-decr">
          <Button
            className="inc-button"
            disabled={MintDisabled()}
            onClick={DecNum}
            style={{ justifyContent: "center" }}
          >
            <RemoveIcon />
          </Button>
          <h6 class="mintcount-text">{props.count}</h6>
          <Button
            className="inc-button"
            disabled={MintDisabled()}
            onClick={IncNum}
            style={{ justifyContent: "center" }}
          >
            <AddIcon />
          </Button>
        </div>
      </>
    );
  }

  function MintDisabled() {
    // console.log("Presale is", props.isPresale);
    // console.log("Is whitelisted is ", props.isWhitelisted);

    if (
      (props.isPresale == false && props.isPublic == false) ||
      (props.isPresale && props.isWhitelisted == false) ||
      // props.isCorrectNetwork == false ||
      props.isSoldOut ||
      props.isMinting ||
      props.isPaused
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ textAlign: "center" }}>
          {!props.metamask ? (
            <div>
              <Button
                onClick={() =>
                  window.open(
                    "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn",
                    "_blank"
                  )
                }
                variant="contained"
                className="connect-button"
              >
                Install Metamask
              </Button>
            </div>
          ) : !props.isWalletConnected ? (
            <div>
              <Button
                onClick={() => window.ethereum.enable()}
                variant="contained"
                className="connect-button"
              >
                Connect Wallet
              </Button>
            </div>
          ) : (
            <div>
              <div>
                {
                  //If presale started, whitelisted, and not sold out
                  props.isPresale == true &&
                  props.isWhitelisted &&
                  !props.isSoldOut == true ? (
                    <IncDec />
                  ) : //If public and not sold out
                  props.isPublic && !props.isSoldOut ? (
                    <IncDec />
                  ) : null
                }
              </div>

              <Button
                disabled={MintDisabled()}
                onClick={props.mintPandenza}
                variant="contained"
                className="connect-button mint-button"
              >
                {props.isSoldOut ? (
                  "SOLD OUT"
                ) : props.isMinting ? (
                  <CircularProgress style={{ color: "white" }} />
                ) : (
                  "MINT"
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MintButton;

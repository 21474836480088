const PresaleText = (props) => {
  return (
    <div
    // style={{backgroundColor: "white"}}
    >
      {
        //If presale not started yet and NOT whitelisted
        props.isPresale == false &&
        props.isPublic == false &&
        props.isWhitelisted == false ? (
          <div className="supply-presaleText">
            {" "}
            Your account is NOT
            currently eligible for the Presale.
          </div>
        ) : //If presale not started yet and whitelisted
        props.isPresale == false &&
          props.isPublic == false &&
          props.isWhitelisted == true ? (
          <div className="supply-presaleText">
            Your account is
            eligible for the Presale.
          </div>
        ) : //If presale started and NOT whitelisted
        props.isPresale == true &&
          props.isWhitelisted == false &&
          !props.isSoldOut ? (
          <div className="supply-presaleText">
            {" "}
            Presale is NOW. Your account is NOT currently eligible for the Presale.
          </div>
        ) : //If presale started and whitelisted
        props.isPresale == true &&
          props.isWhitelisted &&
          !props.isSoldOut == true ? (
          <div>
            <div className="supply-presaleText">
            Presale is NOW. Your account is eligible for the Presale.
            </div>
          </div>
        ) : //If public and not sold out
        null
      }
    </div>
  );
};

export default PresaleText;

/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Countdown from "react-countdown";
import Grid from "@mui/material/Grid";
import MintButton from "./MintButton";
import PresaleText from "./PresaleText";
import ScrollMagic from "scrollmagic";

import background from "./assets/PandenzaLand_BG_Square_4k.jpg";

const Pinned = (props) => {
  const [countdownCompleted, setCountdownCompleted] = useState(false);
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return (
      <div
        class="countdown-subcontainer"
        style={{
          backgroundColor: "rgba(0,0,0,0.8)",
          border: "solid 1px rgba(255,255,255,0.3)",
          // border: "var(--green)",
          padding: ".875rem",
        }}
      >
        <div class="banner">
          {props.publicSaleDateText}
        </div>
        <Grid container className="countdown-grid">
          <Grid item xs={3} className="countdown-item countdown-border-right">
            <div className="countdown-value">
              --
              {/* {days} */}
            </div>
            <div className="countdown-text">DAYS</div>
          </Grid>
          <Grid item xs={3} className="countdown-item countdown-border-right">
            <div className="countdown-value">
              --
              {/* {hours} */}
            </div>
            <div className="countdown-text">HOURS</div>
          </Grid>
          <Grid item xs={3} className="countdown-item countdown-border-right">
            <div className="countdown-value">
              --
              {/* {minutes} */}
            </div>
            <div className="countdown-text">MINUTES</div>
          </Grid>
          <Grid item xs={3} className="countdown-item">
            <div className="countdown-value">
              --
              {/* {seconds} */}
            </div>
            <div className="countdown-text">SECONDS</div>
          </Grid>
        </Grid>

        <div
        // className="hero-presaleText"
        >
          <PresaleText
            metamask={props.metamask}
            isWalletConnected={props.isWalletConnected}
            mintPandenza={props.mintPandenza}
            startDate={props.startDate}
            presaleDate={props.preSaleDate}
            preSaleDateText={props.preSaleDateText}
            maxMintAmount={props.maxMintAmount}
            totalSupply={props.totalSupply}
            currentMaxSupply={props.currentMaxSupply}
            freeMintReserve={props.freeMintReserve}
            isCorrectNetwork={props.isCorrectNetwork}
            currentCost={props.currentCost}
            setCount={props.setCount}
            count={props.count}
            isPaused={props.isPaused}
            isSoldOut={props.isSoldOut}
            isWhitelisted={props.isWhitelisted}
            exceedPerWalletLimit={props.exceedPerWalletLimit}
            isPresale={props.isPresale}
            isPublic={props.isPublic}
            alertState={props.alertState}
            setAlertState={props.setAlertState}
            isMinting={props.isMinting}
            exceedPresaleLimit={props.exceedPresaleLimit}
            publicSaleDateText={props.publicSaleDateText}
          />
        </div>
        {props.isPublic && !props.isSoldOut ? (
          <div className="supply-container">
            <div className="supply-left">
              {parseInt(props.totalSupply) + parseInt(props.freeMintReserve)}/
              {props.currentMaxSupply} PANDENZAZ MINTED&nbsp; |
            </div>
            <div className="supply-left">
              PRICE: {Math.floor(props.currentCost * 1e-18 * 100) / 100}E
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    const controller = new ScrollMagic.Controller();
    const scene = new ScrollMagic.Scene({
      triggerElement: "#hero",
      duration: "100%",
      triggerHook: 0,
    })
      .setPin("#hero")
      .addTo(controller);
  }, []);

  return (
    <div
      className="welcome-img"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container
        id="hero"
        style={{
          height: "100vh",
          //rgba(21, 13, 41, 1) rgb(28, 13, 43)
          backgroundImage: `linear-gradient(180deg, rgb(20, 26, 42,0) 0%, rgb(20, 26, 42, 0) 65%, rgb(0,0,0) 100%)`,
          // backgroundImage: `linear-gradient(180deg, rgb(20, 26, 42,0) 0%, rgb(20, 26, 42, 0) 65%, rgb(20, 26, 42) 100%)`,
          maxWidth: "none",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundPosition: "Top",
          padding: "10% 0",
        }}
        data-aos="fade-in"
      >
        <Box style={{ margin: "2em 0" }}>
          <div className="countdown-container">
            <Countdown
              date={props.startDate}
              renderer={renderer}
              isCompleted={() => setCountdownCompleted(true)}
            />
          </div>
          <div className="hero-minter">
            <MintButton
              metamask={props.metamask}
              isWalletConnected={props.isWalletConnected}
              mintPandenza={props.mintPandenza}
              startDate={props.startDate}
              presaleDate={props.preSaleDate}
              preSaleDateText={props.preSaleDateText}
              maxMintAmount={props.maxMintAmount}
              totalSupply={props.totalSupply}
              currentMaxSupply={props.currentMaxSupply}
              freeMintReserve={props.freeMintReserve}
              isCorrectNetwork={props.isCorrectNetwork}
              currentCost={props.currentCost}
              setCount={props.setCount}
              count={props.count}
              isPaused={props.isPaused}
              isSoldOut={props.isSoldOut}
              isWhitelisted={props.isWhitelisted}
              exceedPerWalletLimit={props.exceedPerWalletLimit}
              isPresale={props.isPresale}
              isPublic={props.isPublic}
              alertState={props.alertState}
              setAlertState={props.setAlertState}
              isMinting={props.isMinting}
              exceedPresaleLimit={props.exceedPresaleLimit}
              goConfetti={props.goConfetti}
              publicSaleDateText={props.publicSaleDateText}
            />
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Pinned;

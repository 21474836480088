/* eslint-disable no-undef */
// import React, { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import Pinned from "./Pinned";
import { Snackbar } from "@material-ui/core";
import Showroom from "./Showroom";
import "aos/dist/aos.css";

const Home = (props) => {
  return (
    <main style={{ overflowX: "hidden"}}>
      <Pinned
        metamask={props.metamask}
        isWalletConnected={props.isWalletConnected}
        mintPandenza={props.mintPandenza}
        startDate={props.startDate}
        presaleDate={props.preSaleDate}
        preSaleDateText={props.preSaleDateText}
        maxMintAmount={props.maxMintAmount}
        totalSupply={props.totalSupply}
        currentMaxSupply={props.currentMaxSupply}
        freeMintReserve={props.freeMintReserve}
        isCorrectNetwork={props.isCorrectNetwork} 
        currentCost={props.currentCost}
        setCount={props.setCount}
        count={props.count}
        isPaused={props.isPaused}
        isSoldOut={props.isSoldOut}
        isWhitelisted={props.isWhitelisted}
        exceedPerWalletLimit={props.exceedPerWalletLimit}
        isPresale={props.isPresale}
        isPublic={props.isPublic}
        alertState={props.alertState}
        setAlertState={props.setAlertState}
        isMinting={props.isMinting}
        exceedPresaleLimit={props.exceedPresaleLimit}
        goConfetti={props.goConfetti}
        publicSaleDateText={props.publicSaleDateText}
      />
      <Showroom />
      <Snackbar
        open={props.alertState.open}
        autoHideDuration={6000}
        onClose={() =>
          props.setAlertState({ ...props.alertState, open: false })
        }
        className="alert"
      >
        <Alert
          onClose={() =>
            props.setAlertState({ ...props.alertState, open: false })
          }
          severity={props.alertState.severity}
          className="alert"
        >
          <div className="alert-text" style={{ fontSize: "15px" }}>{props.alertState.message}</div>
        </Alert>
      </Snackbar>
    </main>
  );
};

export default Home;

/* eslint-disable no-undef */
import "./App.css";
import React, { useEffect, useState } from "react";
import Home from "./Home";
//import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Web3 from "web3";
import PandenzaLand from "./PandenzaLand.json";
import Aos from "aos";
import MerkleTree from "./merkleTree";

const App = () => {
  const [contract, setContract] = useState(null);
  const [account, setAccount] = useState(null);
  const [metamask, setMetamask] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isPaused, setIsPaused] = useState(null);
  const [isSoldOut, setIsSoldOut] = useState(null);
  const [isWhitelisted, setIsWhitelisted] = useState(null);
  const [exceedPerWalletLimit, setExceedPerWalletLimit] = useState(null);
  const [exceedPresaleLimit, setExceedPresaleLimit] = useState(null);
  const [isPresale, setIsPresale] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [nftLimit, setnftLimit] = useState(null);
  const [numberOfToken, setNumberOfToken] = useState(null);
  const [currentMaxSupply, setCurrentMaxSupply] = useState(null);
  const [totalSupply, setTotalSupply] = useState(null);
  const [preSaleDate, setPreSaleDate] = useState(null);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(false);
  const [count, setCount] = useState(1);
  const [isMinting, setIsMinting] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const [freeMintReserve, setFreeMintReserve] = useState(null);
  const [maxMintAmount, setMaxMintAmount] = useState(null);
  const [currentCost, setCurrentCost] = useState(null);
  const [goConfetti, setGoConfetti] = useState(false);
  const [proof, setProof] = useState([]);

  const startDate = new Date(1653958800 * 1000); //For countdown
  const preSaleDateText = "May 30 at 9PM EST"; //not used
  const publicSaleDateText = "Public Sale Date To Be Announced"; //Test: "Public Sale on May 31 at 4PM EST";
  const abi = PandenzaLand;
  const address = "0xb8825232AA75704DA6bFD1917C7AaCbC212fE11D";
  const contractNetworkId = 4;
  const contractNetworkName = "Rinkeby";
  const keccak256 = require("keccak256");
  const Addresses = require("./Whitelist.json");

  useEffect(() => {
    Aos.init({ duration: 1000, once: true });
    connectToMetamaskAndContract();
  }, []);

  useEffect(() => {
    if (contract != null && contract.methods != null && account != null) {
      getContractState();

      contract.events
        .PandenzazMinted()
        .on("data", function (event) {
          console.log(
            `Total minted is now ${event.returnValues._totalSupply}, with last mint by ${event.returnValues._to}`
          );
          setTotalSupply(parseInt(event.returnValues._totalSupply));
          // contract.methods
          //   .totalSupply()
          //   .call()
          //   .then((supply) => {
          //     setTotalSupply(parseInt(supply));
          //   });
        })
        .on("error", console.error);

      contract.events
        .CostChanged()
        .on("data", function (event) {
          console.log(`Cost changed to ${event.returnValues._currentCost}`);
          setCurrentCost(parseInt(event.returnValues._currentCost));
        })
        .on("error", console.error);
    }
  }, [contract]);

  const getContractState = () => {
    //Before presale, presale, or on sale
    contract.methods
      .preSaleDate()
      .call()
      .then((presaleDate) => {
        setPreSaleDate(new Date(presaleDate * 1000));

        contract.methods
          .publicSaleDate()
          .call()
          .then((publicSaleDate) => {
            const now = Date.now();

            ///If before public sale
            if (now < new Date(publicSaleDate * 1000)) {
              ///If in presale
              if (now >= new Date(presaleDate * 1000)) {
                setIsPresale(true);
                setIsPublic(false);

                ///Check if whitelisted
                const leaf = keccak256(account);
                const hexProof = MerkleTree.getHexProof(leaf);
                setProof(hexProof);
                console.log("account is", account);
                console.log("Proof is", proof);
                console.log("Temp proof is", hexProof);
                contract.methods
                  .isWhitelisted(hexProof, account)
                  .call()
                  .then((response) => {
                    setIsWhitelisted(response);
                  });

                //Set maxMintAmount per transaction
                contract.methods
                  .maxMintAmountPresale()
                  .call()
                  .then((amount) => {
                    setMaxMintAmount(parseInt(amount));
                  });

                //Set numberOfTokens, nftLimit, exceedPresaleLimit
                contract.methods
                  .nftPerAddressLimitPresale()
                  .call()
                  .then((limit) => {
                    setnftLimit(parseInt(limit));
                    contract.methods
                      .walletOfOwner(account)
                      .call()
                      .then((tokens) => {
                        setNumberOfToken(parseInt(tokens.length));

                        if (parseInt(tokens.length) >= parseInt(limit)) {
                          setExceedPresaleLimit(true);
                        } else {
                          setExceedPresaleLimit(false);
                        }
                      });
                  });

                //Set currentCost
                contract.methods
                  .getCurrentCost()
                  .call()
                  .then((cost) => {
                    setCurrentCost(parseInt(cost));
                  });

                // Set currentMaxSupply, freeMintReserve,
                // total supply, and if sold out
                contract.methods
                  .preSaleMaxSupply()
                  .call()
                  .then((presaleMaxSupply) => {
                    contract.methods
                      .maxSupply()
                      .call()
                      .then((maxSupply) => {
                        contract.methods
                          .totalSupply()
                          .call()
                          .then((supply) => {
                            setTotalSupply(parseInt(supply));
                            contract.methods
                              .freeMintReserve()
                              .call()
                              .then((freeMintReserve) => {
                                setFreeMintReserve(parseInt(freeMintReserve));
                                if (
                                  parseInt(supply) >=
                                  parseInt(maxSupply) -
                                    parseInt(freeMintReserve)
                                ) {
                                  setIsSoldOut(true);
                                }

                                if (
                                  parseInt(presaleMaxSupply) <=
                                  parseInt(maxSupply) -
                                    parseInt(freeMintReserve)
                                ) {
                                  setCurrentMaxSupply(
                                    parseInt(presaleMaxSupply)
                                  );
                                } else {
                                  setCurrentMaxSupply(
                                    parseInt(maxSupply) -
                                      parseInt(freeMintReserve)
                                  );
                                }
                              });
                          });
                      });
                  });
              }
              ///If before presale
              else {
                setnftLimit(0);
              }
            }
            ///If in public sale
            else {
              setIsPublic(true);
              setIsPresale(false);
              setProof([]);

              //Set maxMintAmount amount per transaction
              contract.methods
                .maxMintAmount()
                .call()
                .then((amount) => {
                  setMaxMintAmount(parseInt(amount));
                });

              //Set nftLimit, numberOfToken, exceedPerWalletLimit
              contract.methods
                .nftPerAddressLimit()
                .call()
                .then((limit) => {
                  setnftLimit(parseInt(limit));
                  contract.methods
                    .walletOfOwner(account)
                    .call()
                    .then((tokens) => {
                      setNumberOfToken(parseInt(tokens.length));

                      if (parseInt(tokens.length) >= parseInt(limit)) {
                        setExceedPerWalletLimit(true);
                      } else {
                        setExceedPerWalletLimit(false);
                      }
                    });
                });

              //Set currentCost
              contract.methods
                .getCurrentCost()
                .call()
                .then((cost) => {
                  setCurrentCost(parseInt(cost));
                });

              // Set currentMaxSupply, freeMintReserve,
              // total supply, and if sold out
              contract.methods
                .maxSupply()
                .call()
                .then((maxSupply) => {
                  setCurrentMaxSupply(parseInt(maxSupply));
                  contract.methods
                    .totalSupply()
                    .call()
                    .then((supply) => {
                      setTotalSupply(parseInt(supply));
                      contract.methods
                        .freeMintReserve()
                        .call()
                        .then((freeMintReserve) => {
                          setFreeMintReserve(parseInt(freeMintReserve));
                          if (
                            parseInt(supply) >=
                            parseInt(maxSupply) - parseInt(freeMintReserve)
                          ) {
                            setIsSoldOut(true);
                          }
                        });
                    });
                });
            }
          });
      });

    //On Pause
    contract.methods
      .paused()
      .call()
      .then((response) => {
        setIsPaused(response);
      });
  };

  const getCost = async () => {
    const cost_per_mint = await contract.methods.getCurrentCost().call();
    return cost_per_mint;
  };

  const connectToMetamaskAndContract = async () => {
    if (window.ethereum) {
      //CONNECTED
      window.web3 = new Web3(window.ethereum);
      setMetamask(true);

      window.web3.eth.getAccounts().then(async (addr) => {
        if (addr.length > 0) {
          setIsWalletConnected(true);
        } else {
          setIsWalletConnected(false);
        }
      });
      connectToContract();
    } else if (window.web3) {
      //CONNECT WALLET
      window.web3 = new Web3(window.web3.currentProvider);

      setMetamask(true);
      connectToContract();
    } else {
      //NEED TO INSTALL METAMASK
      setMetamask(false);
    }
  };

  const connectToContract = async () => {
    const web3 = window.web3;
    // Load account
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);

    // const leaf = keccak256(accounts[0]);
    // const hexProof = MerkleTree.getHexProof(leaf);
    // setProof(hexProof);
    // console.log("account is", accounts[0]);
    // console.log("Proof is", proof);
    // console.log("Temp proof is", hexProof);

    // const hexRoot = MerkleTree.getHexRoot();
    // console.log("hexRoot is", hexRoot);
    // const localIsWhitelisted = MerkleTree.verify(hexProof, leaf, hexRoot);

    console.log("isWalletConnected is", isWalletConnected);
    console.log("account is", accounts[0]);
    console.log("is account null", accounts[0] == null);
    if (accounts[0] != null) {
      const localIsWhitelisted = Addresses.includes(accounts[0]);
      setIsWhitelisted(localIsWhitelisted);
    } else {
      setIsWhitelisted(null);
    }

    console.log("isWhitelisted", isWhitelisted);
    console.log("contract is", contract);
    const networkId = await web3.eth.net.getId();

    console.log("user networkId is", networkId);
    if (networkId == contractNetworkId) {
      const contract = new web3.eth.Contract(abi, address);
      setContract(contract);
      setIsCorrectNetwork(true);
    } else {
      setIsCorrectNetwork(false);
    }
  };

  if (window.web3) {
    window.ethereum.on("accountsChanged", function () {
      window.web3.eth.getAccounts().then((accounts) => {
        window.location.reload();
      });
    });

    window.ethereum.on("networkChanged", function (networkId) {
      window.location.reload();
    });
  }

  const mintPandenza = async () => {
    await connectToMetamaskAndContract();

    console.log("is presale: ", isPresale);
    console.log("is public: ", isPublic);
    console.log("is sold out: ", isSoldOut);
    console.log("is exceedLimit: ", exceedPerWalletLimit);
    console.log("isWhitelisted: ", isWhitelisted);
    console.log("is Paused: ", isPaused);
    console.log("numberOfToken: ", numberOfToken);
    console.log("count: ", count);
    console.log("nftlimit", nftLimit);
    console.log("currentMaxSupply: ", currentMaxSupply);
    console.log("total supply: ", totalSupply);
    console.log("startDate: ", startDate);
    console.log("presale Date: ", preSaleDate);
    console.log("freeMintReserve is", freeMintReserve);
    console.log("totalSupply + count", totalSupply + count);
    console.log(
      "currentMaxSupply - freeMintReserve",
      currentMaxSupply - freeMintReserve
    );
    console.log("numberOfToken + count", numberOfToken + count);
    console.log("Proof is", proof);
  };

  return (
    <Home
      metamask={metamask}
      isWalletConnected={isWalletConnected}
      mintPandenza={mintPandenza}
      startDate={startDate}
      presaleDate={preSaleDate}
      preSaleDateText={preSaleDateText}
      maxMintAmount={maxMintAmount}
      totalSupply={totalSupply}
      currentMaxSupply={currentMaxSupply}
      freeMintReserve={freeMintReserve}
      isCorrectNetwork={isCorrectNetwork}
      currentCost={currentCost}
      setCount={setCount}
      count={count}
      isPaused={isPaused}
      isSoldOut={isSoldOut}
      isWhitelisted={isWhitelisted}
      exceedLimit={exceedPerWalletLimit}
      isPresale={isPresale}
      isPublic={isPublic}
      alertState={alertState}
      setAlertState={setAlertState}
      isMinting={isMinting}
      exceedPresaleLimit={exceedPresaleLimit}
      goConfetti={goConfetti}
      publicSaleDateText={publicSaleDateText}
    />
  );
};

export default App;

import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App.js"
// import SecondGallery from "./SecondGallery.js"


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);


// ReactDOM.render(
//   <React.StrictMode>
//     <SecondGallery />
//   </React.StrictMode>,
//   document.getElementById("secondgallery")
// );

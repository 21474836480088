/* eslint-disable no-undef */
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import a1 from "./assets/samples/A1.gif";
import a2 from "./assets/samples/A2.png";
import a3 from "./assets/samples/A3.png";
import a4 from "./assets/samples/A4.png";
import a5 from "./assets/samples/A5.gif";
import a6 from "./assets/samples/A6.gif";
import b1 from "./assets/samples/B1.gif";
import b2 from "./assets/samples/B2.png";
import b3 from "./assets/samples/B3.png";
import b4 from "./assets/samples/B4.png";
import b5 from "./assets/samples/B5.gif";
import b6 from "./assets/samples/B6.gif";
import c1 from "./assets/samples/C1.gif";
import c2 from "./assets/samples/C2.gif";
import c3 from "./assets/samples/C3.png";
import c4 from "./assets/samples/C4.gif";
import c5 from "./assets/samples/C5.png";
import c6 from "./assets/samples/C6.gif";
import d1 from "./assets/samples/D1.gif";
import d2 from "./assets/samples/D2.png";
import d3 from "./assets/samples/D3.png";
import d4 from "./assets/samples/D4.gif";
import d5 from "./assets/samples/D5.gif";
import d6 from "./assets/samples/D6.gif";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "@mui/material/Container";

export default function Gallery() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    var scrubValue = 2;
    // var xRight = 0;
    // var xLeft = 0;
    var xRight = "60vw";
    var xLeft = "-60vw";
    var yRight = 200;
    var yLeft = 200;

    console.log("xright is", xRight);
    console.log("xleft is", xLeft);

    //scrub in trigger with separate timeline
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#gallery",
        start: "top bottom",
        end: "bottom top",
        scrub: scrubValue,
        // markers: true,
      },
    });

    let tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: "#gallery",
        start: "top bottom",
        end: "bottom top",
        scrub: scrubValue,
        // markers: true,
      },
    });

    // add tweens to that timeline
    tl.to("#gallery-row1", {
      x: xRight,
      y: yRight,
      // rotation: 720,
      ease: "none",
      duration: 1,
      // borderRadius: "1000px",
      // borderColor: "var(--green)",
    });

    tl2.to("#gallery-row2", {
      x: xLeft,
      y: yLeft,
      // rotation: 720,
      ease: "none",
      duration: 1,
      // borderRadius: "1000px",
      // borderColor: "var(--green)",
    });
  }, []);

  // style={{ backgroundColor: "black" }}
  // style={{ marginTop: "8%" }}
  return (
    <div
      className="parentcontainer"
      style={{ backgroundColor: "black" }}
      // id=""
    >
      <div id="gallery" className="customcontainer">
        <div style={{ transform: "rotate(7deg)" }}>
          <div className="translate-left ">
            <div className="gallery-container" id="gallery-row1">
              <img className="gallery-img" src={a1} />
              <img className="gallery-img" src={a2} />
              <img className="gallery-img" src={a3} />
              <img className="gallery-img" src={a4} />
              <img className="gallery-img" src={a5} />
              <img className="gallery-img" src={a6} />
            </div>
          </div>
          <div className="translate-right">
            <div className="gallery-container" id="gallery-row2">
              <img className="gallery-img" src={b1} />
              <img className="gallery-img" src={b2} />
              <img className="gallery-img" src={b3} />
              <img className="gallery-img" src={b4} />
              <img className="gallery-img" src={b5} />
              <img className="gallery-img" src={b6} />
            </div>
          </div>
          <div className="translate-left">
            <div className="gallery-container" id="gallery-row1">
              <img className="gallery-img" src={c1} />
              <img className="gallery-img" src={c2} />
              <img className="gallery-img" src={c3} />
              <img className="gallery-img" src={c4} />
              <img className="gallery-img" src={c5} />
              <img className="gallery-img" src={c6} />
            </div>
          </div>
          <div className="translate-right">
            <div className="gallery-container" id="gallery-row2">
              <img className="gallery-img" src={d1} />
              <img className="gallery-img" src={d2} />
              <img className="gallery-img" src={d3} />
              <img className="gallery-img" src={d4} />
              <img className="gallery-img" src={d5} />
              <img className="gallery-img" src={d6} />
            </div>
          </div>
        </div>
      </div>
      <Container
        className="fade-container"
        style={{
          // backgroundColor: "white",
          backgroundImage: `linear-gradient(180deg, rgb(20, 26, 42, 0) 0%, rgb(20, 26, 42, 0) 80%, rgb(20, 26, 42) 100%)`,
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundPosition: "Top",
          // marginBottom: "5%"
        }}
      ></Container>
      {/* Top fade */}
      <Container
        className="fade-container"
        style={{
          backgroundImage: `linear-gradient(0deg, rgb(20, 26, 42, 0) 0%, rgb(0,0,0, 0) 95%, rgb(0,0,0) 98%)`,
          // backgroundImage: `linear-gradient(0deg, rgb(20, 26, 42, 0) 0%, rgb(20, 26, 42, 0) 95%, rgb(20, 26, 42) 98%)`,
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "center",
          backgroundSize: "cover",
          backgroundPosition: "Top",
        }}
      ></Container>
    </div>
  );
}
